<template>
  <div id="page-login">
    <div class="xs-p-20 xs-fill-white">
      <div class="form-section">
        <div class="form-section__heading"><span>{{$t('sign_in_out_with')}}</span></div>
        <div class="md-flex">
          <fb-signin-button
            :params="fbSignInParams"
            @success="onSignInFBSuccess"
            @error="onSignInFBError"
            class="login-fb btn btn-primary xs-full-width xs-mr-5">
            <i class="fa fa-facebook fa-fw mr-5"></i>{{$t('sign_in_fb')}}
          </fb-signin-button>
          <g-signin-button v-if="googleSignInParams.client_id"
                           :params="googleSignInParams"
                           @success="onSignInSuccess"
                           @error="onSignInError"
                           class="login-gg btn btn-danger xs-full-width xs-ml-5">
            <div class="w-100" @click="() => {
                eventTrackingLoginStart('google');
                eventTrackingLoginSubmit('google');
              }">
              <i class="fa fa-google fa-fw mr-5"></i>{{$t('sign_in_google')}}
            </div>
          </g-signin-button>
        </div>
      </div>

      <div class="form-section box-section" style="display: none">
        <p class=" alert alert-warning text-center t14 cl-red " v-if="method && method ==='couponattach'">
          {{$t('login.coupon_message')}}</p>
        <strong>
          {{$t('login_noti_title')}}<br/>
        </strong>
        <div class="text-grey">
          <ul class="rule">
            <li>{{$t('login_content_1')}}</li>
            <li>{{$t('login_content_2')}}</li>
            <li>{{$t('login_content_3')}}</li>
          </ul>
        </div>
      </div>

      <div class="form-section">
        <form @submit="checkForm"
              action=""
              method="post"
              novalidate="true">

          <div class="form-section__heading"><span>{{$t('sign_in_out')}}</span></div>
          <div class="alert alert-danger" role="alert" v-if="errors.summary.length > 0">
            {{ errors.summary }}
          </div>
          <div class="form-field">
            <div class="xs-flex xs-items-center xs-mb-5">
              <label class="label label-input xs-mb-0" for="emailAddress">{{$t('email')}}</label>
              <span v-if="errors.emailAddress" class="xs-text-right typo-l7 xs-text-red xs-ml-auto ">
                                <span>{{ errors.emailAddress }}</span>
                            </span>
            </div>
            <input id="emailAddress" ref="emailAddress" class="input xs-mb-10 "
                   v-bind:class="{ 'has-errors': errors.emailAddress }"
                   name="emailAddress" v-model="emailAddress"
                   value="" v-bind:placeholder="$t('local_holder_login_email')"
                   type="text"
            >
          </div>
          <div class="form-field">
            <div class="xs-flex xs-items-center xs-mb-5">
              <label class="label label-input xs-mb-0" for="password">{{$t('your_pass')}}</label>
              <span v-if="errors.password" class="xs-text-right typo-l7 xs-text-red xs-ml-auto ">
                                <span>{{ errors.password }}</span>
                            </span>
            </div>
            <input id="password" ref="password" class="input xs-mb-10 " v-bind:class="{ 'has-errors': errors.password }"
                   v-model="password" name="password" value="" v-bind:placeholder="$t('local_holder_login_password')"
                   type="password" required>
          </div>
          <router-link to="/getmypassword" class="forget-pass text-red t15 " style="text-decoration:none">
            {{$t('forgot_password')}}
          </router-link>
          <input type="submit" class="button button--primary" v-bind:value="$t('btn_sign_in')">
        </form>
      </div>
      
      <div class="form-section hidden-xs">
        <router-link to="/register" class="button button--primary">{{$t('btn_create_account')}}</router-link>
      </div>
      <div class="form-section visible-xs">
        <div class="form-section__heading hidden-sm hidden-xs"><span>{{$t('sign_in_remember')}}</span></div>
        <div class="form-section__heading hidden-md hidden-lg"><span>{{$t('dont_have_account')}}</span></div>
        <router-link to="/register" class="button button--primary">{{$t('btn_create_account')}}</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import GSignInButton from 'vue-google-signin-button'
import FBSignInButton from 'vue-facebook-signin-button'
import main from '../main.js'

Vue.use(FBSignInButton)
Vue.use(GSignInButton)

export default {
  components: {
    Vue
  },
  props: {
    method: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {},
  created () {
    if (this.$auth.check()) {
      return this.redirectToPage(this.$const.SLUG_LIST.DEAL)
    }
  },
  mounted () {
    this.eventTrackingScreenLoad(this, {screen: {'name': 'account.login', 'type': 'account'}})
    this.ggData()
    this.fbData()
  },
  beforeCreate () {
    const gapi = document.createElement('script')
    gapi.setAttribute('src', 'https://apis.google.com/js/api:client.js')
    document.head.appendChild(gapi)
    window.fbAsyncInit = function ($fbId) {
      if ($fbId.app_id) {
        FB.init({
          appId: $fbId.app_id,
          cookie: true, // enable cookies to allow the server to access the session
          xfbml: true, // parse social plugins on this page
          version: 'v3.1' // use graph api version 2.8
        })
      }
    };
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      js.rel = 'preconnect'
      fjs.parentNode.insertBefore(js, fjs)
    }(document, 'script', 'facebook-jssdk'))
  },
  watch: {
    emailAddress: function (newValue, oldValue) {
      this.errors.emailAddress = ''
      if (this.emailAddress && !this.sendLoginStart) {
        this.eventTrackingLoginStart()
        this.sendLoginStart = true
      }
      if (!this.emailAddress) {
        this.errors.emailAddress = this.$t('required_display')
      } else if (!this.validEmail(this.emailAddress)) {
        this.errors.emailAddress = this.$t('validate_invalid_email')
      }
    },
    password: function (newValue, oldValue) {
      this.errors.password = ''
      if (!this.password) {
        this.errors.password = this.$t('required_display')
      }
    }
  },
  data () {
    return {
      sendLoginStart: false,
      emailAddress: '',
      password: '',
      errors: {
        'emailAddress': '',
        'password': '',
        'summary': ''
      },
      googleSignInParams: {
        client_id: ''
      },
      fbSignInParams: {
        scope: 'email',
        return_scopes: true
      },
      app_secret: '',
      app_id: ''
    }
  },
  methods: {
    checkForm: function (e) {
      this.errors = {
        'emailAddress': '',
        'password': '',
        'summary': ''
      }
      let flag = false
      if (this.emailAddress && this.validEmail(this.emailAddress) && this.password) {
        this.eventTrackingLoginSubmit()
        this.callbackLogin({email: this.emailAddress, password: this.password}, 'email')
        e.preventDefault()
        return false
      }

      if (!this.emailAddress) {
        this.errors.emailAddress = this.$t('required_display')
        flag = true
        this.$refs.emailAddress.focus()
      } else if (!this.validEmail(this.emailAddress)) {
        this.errors.emailAddress = this.$t('validate_invalid_email')
        flag = true
        this.$refs.emailAddress.focus()
      }

      if (!this.password) {
        this.errors.password = this.$t('required_display')
        if (!flag) {
          this.$refs.password.focus()
        }
      }

      e.preventDefault()
    },
    validEmail: function (email) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    onSignInSuccess (googleUser) {
      let response = googleUser.getAuthResponse('id_token')
      let profile = googleUser.getBasicProfile() // etc etc
      let params = {
        social_id: profile.getId(),
        email: profile.getEmail(),
        first_name: profile.getFamilyName(),
        last_name: profile.getGivenName(),
        social: this.$const.GOOGLE_LOGIN,
        social_token: response.id_token
      }
      this.callbackLogin(params, 'google')
    },
    onSignInError (error) {
      console.error(error, 'ERROR OCCURRED WHILE SSO')
      main.$notify({
        type: 'danger',
        title: this.$t('customer.login_registration.notice_title'),
        text: this.$t('customer.login_registration.notice_1')
      })
    },
    onSignInFBSuccess (response) {
      let accessToken = response.authResponse.accessToken
      // let appsecret_proof = CryptoJS.HmacSHA256(accessToken, this.app_secret).toString(CryptoJS.enc.Hex);
      FB.api('/me', 'get', {fields: 'id,name,first_name,last_name,middle_name,gender,email,birthday,picture'}, dude => {
        if (typeof dude.email !== 'undefined') {
          let params = {
            social_id: dude.id,
            email: dude.email,
            first_name: dude.first_name,
            last_name: dude.last_name,
            social: this.$const.FACEBOOK_LOGIN,
            social_token: accessToken
            // appsecret_proof: appsecret_proof
          }
          this.callbackLogin(params, 'facebook')
        } else {
          this.$router.push({'name': 'register'})
        }
      })
    },
    onSignInFBError (error) {
      console.error(error, 'ERROR OCCURRED WHILE SSO')
      main.$notify({
        type: 'danger',
        title: this.$t('customer.login_registration.notice_title'),
        text: this.$t('customer.login_registration.notice_2')
      })
    },
    callbackLogin: function (params, method) {
      let user = params

      user.localFirstName = params.first_name
      user.localLastName = params.last_name
      var vm = this
      this.$auth.login({
        data: user,
        success: function (response) {
          if (response.data.status) {
            // update cart
            vm.$cart.updateCartUuid(vm)
            vm.eventTrackingLoginSuccess(vm, method)
            vm.eventTrackingFormSuccess({id: 'additional-details-hut-rewards'}, method)
            main.$notify({
              type: 'danger',
              title: this.$t('customer.login_registration.notice_title'),
              text: this.$t('customer.login_registration.notice_success')
            })
            if (vm.$route.query && vm.$route.query.hasOwnProperty('returnUrl')) {
              vm.$router.push(vm.$route.query.returnUrl)
            } else {
              this.$root.$emit('onProcessChangeCategory')
            }
            // if (vm.method && vm.$root.$route.query.callback) {
            //   vm.$router.push({
            //     name: 'coupon-attach',
            //     params: {name: vm.$root.$route.query.name, code: vm.$root.$route.query.code}
            //   })
            // } else {
            //   if (vm.$route.query && vm.$route.query.hasOwnProperty('returnUrl')) {
            //     vm.$router.push(vm.$route.query.returnUrl)
            //   } else {
            //     // vm.redirectToPage(vm.$const.SLUG_LIST.DEAL)
            //     vm.$router.push('home')
            //   }
            // }
          } else {
            this.errors.emailAddress = response.data.error.msg
          }
        },
        error: function (data) {
          this.errors.summary = vm.$t('login_error')
          // main.$notify({
          // 	type: 'danger',
          // 	title: 'Error',
          // 	text: 'Login failed. Please check it again!'
          // })
        }
      })
    },
    ggData: function () {
      let param = {scope: 'social.gg'}
      var vm = this
      this.axios.apiTenant.getSocialByClient(param, function (response) {
        vm.googleSignInParams.client_id = response.data.web
      }, function (error) {
        console.log(`error: ${error}`)
      })
      // this.axios.get('product-tenant/config?scope=social.gg')
      //   .then(respones => {
      //     this.googleSignInParams.client_id = respones.data
      //   }).catch(error => {
      //   console.log(error)
      // })
    },
    fbData: function () {
      var vm = this
      let param = {scope: 'social.fb'}
      this.axios.apiTenant.getSocialByClient(param, function (response) {
        vm.app_secret = response.app_secret

        window.fbAsyncInit(response.data)
      }, function (error) {
        console.log(`error: ${error}`)
      })
      // this.axios.get('product-tenant/config?scope=social.fb')
      //   .then(respones => {
      //     window.fbAsyncInit(respones.data)
      //   }).catch(error => {
      //   console.log(error)
      // })
    }
  }
}
</script>

<style>
  .g-signin-button {
    /* This is where you control how the button looks. Be creative! */
    display: inline-block;
    padding: 4px 8px;
    border-radius: 3px;
    background-color: #3c82f7;
    color: #fff;
    box-shadow: 0 3px 0 #0f69ff;
  }

  .fb-signin-button {
    /* This is where you control how the button looks. Be creative! */
    display: inline-block;
    padding: 4px 8px;
    border-radius: 3px;
    background-color: #4267b2;
    color: #fff;
  }
</style>
